import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import Rating from "./Rating";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Carousel } from "react-responsive-carousel";
import { listProducts } from "../actions/productActions";
import {
  listProductDetails,
  createProductReview,
} from "../actions/productActions";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";
import { render } from "react-dom";
// import Carousel from "../components/Slide";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Meta from "../components/Meta"; // ✅ Import Meta for SEO
import { Helmet } from "react-helmet";
import { green } from "colors";

const slugify = (name) => {

  if (!name || typeof name !== "string") {
    // Fallback to a default slug if `name` is not provided or invalid
    return "default-slug";
  }

  return name
    .toLowerCase() // Convert to lowercase
    .trim() // Remove leading and trailing spaces
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^a-z0-9-]+/g, "") // Remove special characters except hyphens
    .replace(/^-+|-+$/g, "") // Remove leading or trailing hyphens
    .replace(/\)+$/, ""); // Remove trailing parentheses
};


const Product = ({ product, match }) => {
  const { slug } = useParams();
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [products, setProducts] = useState(null);
  const [error, setError] = useState(null); // Error state
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    const fetchProduct = async () => {
      console.log("Slug:", slug);
      setLoading(true);
      try {
        // const { data } = await axios.get(`/api/products/${brand}/${slug}`);
        const { data } = await axios.get(`/api/products/${slug}`);
        setProducts(data);
      } catch (err) {
        setError(err.message || "Failed to load product.");
      } finally {
        setLoading(false);
      }
    };
    fetchProduct();
  }, [slug]);

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`);
  };

  const [toggler, setToggler] = useState(false);

  const [show, setShow] = useState(false);
  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const checkoutHandler = () => {
    history.push("/login?redirect=shipping");
  };


  // const handleNavigate = (productId) => {
  //   history.push(`/product/${productId}`);
  //   window.scrollTo(0, 0);
  // };
  const handleNavigate = (productName) => {
    const productSlug = slugify(productName); // Generate slug for the product
    history.push(`/product/${productSlug}`);
    window.scrollTo(0, 0);
  }

  return (

    <>

      {/* Add dynamic meta tags using Helmet */}
      <Helmet>
        <title>Interwave Computers - Leading Computer, Laptop & Gadget Shop in Khulna</title>
        {/* <meta name="description" content={product.description} /> */}
        <meta name="keywords" content={product.keywords ? product.keywords.join(', ') : ""} />
      </Helmet>

      <Card className="my-3  rounded product_card ">
        <div className="card-head card_head_custom">
          {/* <span className="offer"> Save: 5%</span> */}
          <span className="quick-view-button-heart">
            <i class="fas fa-heart"></i>
          </span>
          {product.countInStock === 0 ? (
            <Link
            // to={`/product/${product._id}`}
            >
              <Card.Img src={product.image} variant="top" />
            </Link>
          ) : (
            <Link to={`/product/${product.slug}`}>
              <Card.Img src={product.image} variant="top" />
            </Link>
          )}
          <span class="quick-view-button w-100" onClick={handleShow}>
            Quick View
          </span>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={6} className="for_padding">
                  {/* <Carousel onClick={() => setToggler(!toggler)} /> */}
                  <Carousel width autoPlay>
                    {[
                      product.image,
                      ...(product.additionalImages ? product.additionalImages : [])
                    ].map((image, index) => (
                      <div key={index}>
                        <img alt="" className="img-fluid" src={image} />
                      </div>
                    ))}
                  </Carousel>
                </Col>
                <Col md={6}>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h2 className="product_name">{product.name}</h2>
                    </ListGroup.Item>
                    <ListGroup.Item className="product_code">
                      Product Code: {product.productCode}
                    </ListGroup.Item>
                    <ListGroup.Item className="product_price">
                      Price:{" "}
                      <img
                        width={12}
                        className="img-fluid mr-1 mb-1 "
                        src="../images/taka_green.svg"
                      />
                      {/* {product.price} */}
                      {product.category === "AC" ? (
                        <>
                          <s style={{ marginRight: '5px' }}>{product.price}</s>
                          <strong style={{ color: 'green' }}>
                            {(product.price - 3000)}
                          </strong></>
                      ) : (
                        <strong style={{ color: 'green' }}>
                          {product.price}
                        </strong>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Rating
                        value={product.rating}
                        text={`${product.numReviews} reviews`}
                      />
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <h3 className="product_name pt-0 pb-0 mb-0">Key Features</h3>
                      {product.description}
                    </ListGroup.Item>
                    <ListGroup.Item className="product_price mb-3 ">
                      {product.countInStock > 0 ? "In Stock" : "Out Of Stock"}
                    </ListGroup.Item>
                  </ListGroup>
                  <div className="d-flex">
                    {product.countInStock > 0 && (
                      <ListGroup.Item className="mr-3 ml-3 w-50 customWidth">
                        <Form.Control
                          className="form_c"
                          as="select"
                          value={qty}
                          onChange={(e) => setQty(e.target.value)}
                        >
                          {[...Array(product.countInStock).keys()].map((x) => (
                            <option key={x + 1} value={x + 1}>
                              {x + 1}
                            </option>
                          ))}
                        </Form.Control>
                      </ListGroup.Item>
                    )}
                    <Link to={`/product/${product.slug}`}>
                      <Button className="btn-block color_dark " type="button">
                        {" "}
                        Add To Cart
                      </Button>
                    </Link>
                    {/* <Button
                                            onClick={addToCartHandler}
                                            className='btn-block color_dark w-50'
                                            type='button'
                                            disabled={product.countInStock === 0}
                                        >
                                            Add To Cart
                                    </Button> */}
                  </div>
                  <div className="mt-4"></div>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>
        <Card.Body className="pt-0">
          {/* <Link to={`/product/${product._id}`}>
          <Card.Title as="div">
            <strong className="product_name ">{product.name}</strong>
          </Card.Title>
        </Link> */}
          {product.countInStock === 0 ? (
            <div
            // onClick={() => handleNavigate(product._id)}
            >
              <Card.Title as="div" className="mb-0">
                <strong className="product_name mb-0">{product.name}</strong>
              </Card.Title>
            </div>
          ) : (
            <div onClick={() => handleNavigate(product.name)}>
              <Card.Title as="div" className="mb-0">
                <h3><strong className="product_name mb-0">{product.name}</strong></h3>
              </Card.Title>
            </div>
          )}
          <Card.Text as="div">
            <Rating
              value={product.rating}
              text={`${product.numReviews} reviews`}
            />
          </Card.Text>


          {product.countInStock === 0 ? (
            <p className="product_price">
              <img
                width={12}
                className="img-fluid mr-1 mb-1"
                src="../images/taka_green.svg"
              />
              {' OUT OF STOCK '}
            </p>
          ) : (
            <p className="product_price p-0 pt-1">
              <img
                width={12}
                className="img-fluid mr-1 mb-1"
                src="../images/taka_green.svg"
              />
              <strong style={{ color: 'green' }}>{product.category === "AC" ? (
                <>
                  <s style={{ marginRight: '5px' }}>{product.price.toLocaleString()}</s>
                  <strong style={{ color: 'green' }}>
                    {(product.price - 3000).toLocaleString()}
                  </strong></>
              ) : (
                <strong style={{ color: 'green' }}>{product.price.toLocaleString()}</strong>
              )}</strong>
            </p>
          )}

          {product.countInStock === 0 ? (
            <div
            // onClick={() => handleNavigate(product._id)}
            >
              <Button className="btn product_btn w-100">
                <i class="fas fa-shopping-cart mr-2"></i> Up Coming
              </Button>{" "}
            </div>
          ) : (
            <div onClick={() => handleNavigate(product.name)}>
              <Button className="btn product_btn w-100">
                <i class="fas fa-shopping-cart mr-2"></i> Buy Now
              </Button>{" "}
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Product;

