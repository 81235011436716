import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Col, Carousel, FormControl } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listProductDetails, updateProduct } from '../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants'
import { useParams } from 'react-router-dom';



const ProductEditScreen = ({ match, history }) => {
    const productId = match.params.slug
    // const productId = match.params.id

    const [imageFields, setImageFields] = useState([{ preview: null }]); // Manage multiple image fields


    // const uploadImageHandler = (e, index) => {
    //     const file = e.target.files[0];
    //     if (file) {

    //         const maxSizeInMB = 5; // Set max size limit (e.g., 5MB)
    //         if (file.size > maxSizeInMB * 1024 * 1024) {
    //             alert(`File size exceeds ${maxSizeInMB}MB. Please upload a smaller file.`);
    //             return;
    //         }
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             const newFields = [...imageFields];
    //             newFields[index].file = file; // Save the selected file
    //             newFields[index].preview = reader.result; // Save the preview URL
    //             setImageFields(newFields);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };

    const uploadImageHandler = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const updatedFields = [...imageFields];
            updatedFields[index].preview = URL.createObjectURL(file); // Set preview
            updatedFields[index].file = file; // Optionally save the file
            setImageFields(updatedFields);
        }
    };

    const addImageField = () => {
        setImageFields([...imageFields, { preview: null }]); // Add a new empty field
    };
    const removeImageField = (index) => {
        const newFields = imageFields.filter((_, i) => i !== index); // Remove the field at the specified index
        setImageFields(newFields);
    };

    const [name, setName] = useState('')
    const [price, setPrice] = useState(0)
    const [productCode, setProductCode] = useState('')
    const [imagePreview, setImagePreview] = useState(null)


    const [image, setImage] = useState('')
    const [selectedImage, setSelectedImage] = useState(image); // Initialize with the main image
    const [additionalImages, setAdditionalImages] = useState([])
    const [brand, setBrand] = useState('')
    const [category, setCategory] = useState('')
    const [categories, setCategories] = useState([])
    const [subCategory, setSubCategory] = useState('')
    const [countInStock, setCountInStock] = useState(0)
    const [feature, setFeature] = useState('')
    const [description, setDescription] = useState('')
    const [fontCategory, setFontCategory] = useState('')
    const [descriptions, setDescriptions] = useState('')
    const [uploading, setUploading] = useState(false)
    const dispatch = useDispatch()


    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [textAlign, setTextAlign] = useState("left"); // Text alignment
    const [textColor, setTextColor] = useState("#000000"); // Default black color
    const [textSize, setTextSize] = useState("16px"); // Default font size
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isSuperscript, setIsSuperscript] = useState(false);
    const [isSubscript, setIsSubscript] = useState(false);


    const [isBulletedList, setIsBulletedList] = useState(false);
    const [isNumberedList, setIsNumberedList] = useState(false);
    const [fontFamily, setFontFamily] = useState("Arial");



    const [isBlockquote, setIsBlockquote] = useState(false);


    const [bgColor, setBgColor] = useState("#ffffff");


    const toggleStrikethrough = () => {
        setIsStrikethrough(!isStrikethrough);
    };

    const toggleSuperscript = () => {
        setIsSuperscript(!isSuperscript);
        setIsSubscript(false); // Disable subscript if superscript is enabled
    };

    const toggleSubscript = () => {
        setIsSubscript(!isSubscript);
        setIsSuperscript(false); // Ensure superscript is disabled
    };
    const toggleBulletedList = () => setIsBulletedList(!isBulletedList);
    const toggleNumberedList = () => setIsNumberedList(!isNumberedList);
    const insertTable = () => {
        const rows = prompt("Enter number of rows:");
        const cols = prompt("Enter number of columns:");
        if (rows && cols) {
            const table = `<table border="1">${"<tr>" + "<td>Cell</td>".repeat(cols) + "</tr>".repeat(rows)}</table>`;
            setDescription((prev) => prev + table);
        }
    };


    // Handle font style changes
    const toggleBold = () => {
        setIsBold(!isBold); // Toggle the bold state
        setDescription((prev) =>
            isBold ? prev.replace(/<b>|<\/b>/g, "") : `<b>${prev}</b>` // Wrap in <b> tags or remove them
        );
    };
    const toggleItalic = () => {
        setIsItalic(!isItalic);
        setDescription((prev) =>
            isItalic ? prev.replace(/<i>|<\/i>/g, "") : `<i>${prev}</i>`
        );
    };
    const toggleUnderline = () => {
        setIsUnderline(!isUnderline);
        setDescription((prev) =>
            isUnderline ? prev.replace(/<u>|<\/u>/g, "") : `<u>${prev}</u>`
        );
    };

    const [historys, setHistory] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    const undo = () => {
        if (historys.length > 0) {
            const lastState = historys.pop();
            setRedoStack([...redoStack, description]);
            setDescription(lastState);
            setHistory([...historys]);
        }
    };

    const insertHorizontalRule = () => {
        const hrTag = "<hr />";
        setDescription((prev) => prev + hrTag);
    };

    const insertSymbol = () => {
        const symbol = prompt("Enter Symbol:");
        if (symbol) {
            setDescription((prev) => prev + symbol);
        }
    };


    const toggleBlockquote = () => {
        // Toggle the blockquote state
        setIsBlockquote((prev) => !prev);

        // Get the current selection
        const selection = window.getSelection();
        if (!selection.rangeCount) return;

        const range = selection.getRangeAt(0);
        const parentElement = range.commonAncestorContainer.parentNode;

        // Check if the selection is already inside a blockquote
        if (parentElement.nodeName === "BLOCKQUOTE") {
            // Remove blockquote formatting
            const blockquoteParent = parentElement.parentNode;
            while (parentElement.firstChild) {
                blockquoteParent.insertBefore(parentElement.firstChild, parentElement);
            }
            blockquoteParent.removeChild(parentElement);
        } else {
            // Add blockquote formatting
            const blockquote = document.createElement("blockquote");
            blockquote.style.borderLeft = "5px solid #ccc";
            blockquote.style.paddingLeft = "10px";
            blockquote.style.fontStyle = "italic";

            range.surroundContents(blockquote);
        }
    };



    const insertImage = () => {
        const url = prompt("Enter Image URL:");
        if (url) {
            const imageTag = `<img src="${url}" alt="Image" style="max-width: 100%;" />`;
            setDescription((prev) => prev + imageTag);
        }
    };


    const redo = () => {
        if (redoStack.length > 0) {
            const nextState = redoStack.pop();
            setHistory([...historys, description]);
            setDescription(nextState);
        }
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                // Insert the image into the description editor with smaller size
                const imageTag = `<img src="${event.target.result}" alt="Uploaded Image" style="max-width: 200px; height: auto; margin: 10px 0;" />`;
                setDescription((prev) => prev + imageTag);
            };
            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };



    // Save changes to history
    useEffect(() => {
        setHistory([...historys, description]);
    }, [description]);


    const addLink = () => {
        const url = prompt("Enter the URL");
        if (url) {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.textContent = selection.toString();
            range.deleteContents();
            range.insertNode(anchor);
        }
    };

    const addAnchorTag = () => {
        const url = prompt("Enter URL:");
        const text = prompt("Enter Link Text:");
        if (url && text) {
            // Insert the anchor tag with skyblue color
            const anchorTag = `<a href="${url}" target="_blank" style="color: blue; text-decoration: underline;">${text}</a>`;
            setDescription((prev) => prev + anchorTag);
        }
    };


    const productDetails = useSelector((state) => state.productDetails)
    const { loading, error, product } = productDetails

    const productUpdate = useSelector((state) => state.productUpdate)
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = productUpdate



    const ImageUploadComponent = () => {
        const [imagePreview, setImagePreview] = useState(null);
    }

    const handleAddImageField = () => {
        setAdditionalImages([...additionalImages, '']);
    };
    const handleRemoveImageField = (index) => {
        const newImages = [...additionalImages];
        newImages.splice(index, 1);
        setAdditionalImages(newImages);
    };

    const [specifications, setSpecifications] = useState([]);
    const [specification, setSpecification] = useState([]);

    const [formData, setFormData] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const handleInputChange = (titleIndex, dependencyIndex, fieldName, value) => {
        const updatedFormData = [...formData];
        if (dependencyIndex === -1) {
            updatedFormData[titleIndex][fieldName] = value;
        } else {
            updatedFormData[titleIndex].dependencies[dependencyIndex][fieldName] = value;
        }
        setFormData(updatedFormData);
    };
    const handleAddButtonClick = () => {
        setShowForm(true);
        setFormData([...formData, { title: '', dependencies: [] }]);
    };

    const handleRemoveButtonClick = (titleIndex) => {
        const updatedFormData = [...formData];
        updatedFormData.splice(titleIndex, 1);
        setFormData(updatedFormData);
    };

    const handleAddButtonClick1 = (index) => {
        const updatedFormData = [...formData];
        updatedFormData[index].dependencies.push({ ProcessorBrand: '', brand: '' });
        setFormData(updatedFormData);
    };

    const handleRemoveButtonClick1 = (titleIndex, dependencyIndex) => {
        const updatedFormData = [...formData];
        updatedFormData[titleIndex].dependencies.splice(dependencyIndex, 1);
        setFormData(updatedFormData);
    };


    const [showRamDetails, setShowRamDetails] = useState([]);

    const handleUrlChange = (e, index) => {
        const updatedFields = [...imageFields];
        updatedFields[index].url = e.target.value;
        updatedFields[index].preview = e.target.value; // Update preview dynamically for URLs
        setImageFields(updatedFields);
    };


    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: PRODUCT_UPDATE_RESET });
            history.push('/admin/productlist');
        } else {
            // Ensure `product` is not undefined and has valid data
            if (!product || !product.name || product.slug !== productId) {
                dispatch(listProductDetails(productId));
            } else {
                setName(product.name || '');
                setPrice(product.price || 0);
                setProductCode(product.productCode || '');
                setImage(product.image || '');
                setAdditionalImages(product.additionalImages || []);
                setImagePreview(product.imagePreview || '');
                setBrand(product.brand || '');
                setCategory(product.category || '');
                setSubCategory(product.subCategory || '');
                setCountInStock(product.countInStock || 0);
                setFeature(product.feature || '');
                setDescription(product.description || '');
                setFontCategory(product.fontCategory || 'Arial');
                setIsBold(product.isBold || false);
                setIsItalic(product.isItalic || false);
                setIsUnderline(product.isUnderline || false);
                setDescriptions(product.descriptions || []);
                setSpecifications(product.specifications || []);
                setSpecification(product.specification || []);

                // Ensure `product.specifications` exists before mapping
                if (Array.isArray(product.specifications)) {
                    const formData = product.specifications.map(spec => ({
                        title: spec.name,
                        dependencies: spec.values.map(value => ({
                            ProcessorBrand: value.name,
                            brand: value.value
                        }))
                    }));
                    setFormData(formData);
                }

                if (Array.isArray(product.specification)) {
                    const formData = product.specification.map(spec => ({
                        title: spec.name,
                        dependencies: spec.values.map(value => ({
                            ProcessorBrand: value.name,
                            brand: value.value
                        }))
                    }));
                    setFormData(formData);
                }

                setShowForm(true);
            }
        }
    }, [dispatch, history, productId, product, successUpdate]);

    // useEffect(() => {
    //     console.log('Product Sub-Category:', product.subCategory);
    //     if (product.subCategory) {
    //         setSubCategory(product.subCategory || '');
    //     }
    // }, [product.subCategory]);
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('/category2.json');
                const data = await response.json();
                setCategories(data);

                // Check if `product` is defined before accessing `product.category`
                if (product && product.category) {
                    setCategory(product.category);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, [product]); // Dependency array includes `product`

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };
    const handleSubCategoryChange = (e) => {
        const selectedSubCategory = e.target.value;
        setSubCategory(selectedSubCategory);

        // Generate product code dynamically
        const subCategoryPrefix = selectedSubCategory
            ? selectedSubCategory.trim().substring(0, 3).toUpperCase()
            : "DEF"; // Default prefix
        const uniqueCode = Math.floor(100000000 + Math.random() * 900000000);
        setProductCode(`${subCategoryPrefix}${uniqueCode}`);
    };


    const handleBrandChange = (e) => {
        setBrand(e.target.value);
    };

    const uploadfileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        setUploading(true)
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setImage(reader.result); // Set the main image for preview
            };

            reader.readAsDataURL(file); // Convert the file to a Data URL for preview
        }

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/upload', formData, config)

            setImage(data)
            setUploading(false)
        }
        catch (error) {
            console.log(error)
            setUploading(false)
        }
    }

    const uploadAdditionalImageHandler = (e, index) => {
        const file = e.target.files[0];
        const updatedAdditionalImages = [...additionalImages];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                updatedAdditionalImages[index] = reader.result; // Update the specific additional image
                setAdditionalImages(updatedAdditionalImages); // Set updated images
            };

            reader.readAsDataURL(file);
        }
    };

    const addAdditionalImageField = () => {
        setAdditionalImages([...additionalImages, ""]); // Add an empty field for a new additional image
    };

    const uploadfileHandlerAdditional = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/upload', formData, config)


            console.log("before", additionalImages)

            setAdditionalImages(prevImages => {
                const filteredImages = prevImages.filter(image => image.length > 0);
                return [...filteredImages, data];
            });
            console.log("we", additionalImages)
            setUploading(false)
        }
        catch (error) {
            console.log(error)
            setUploading(false)
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            updateProduct({
                // _id: productId,
                slug: productId,
                name,
                price,
                productCode,
                image,
                additionalImages,
                imagePreview,
                brand,
                category,
                subCategory,
                countInStock,
                feature,
                description,
                fontCategory,
                isBold,
                isItalic,
                isUnderline,
                descriptions,
                specifications: formData.map(({ title, dependencies }) => ({
                    name: title,
                    values: dependencies.map(({ ProcessorBrand, brand }) => ({
                        name: ProcessorBrand,
                        value: brand
                    }))
                })),
                specification: formData.map(({ title, dependencies }) => ({
                    name: title,
                    values: dependencies.map(({ ProcessorBrand, brand }) => ({
                        name: ProcessorBrand,
                        value: brand
                    }))
                }))
            })
        );
    };


    return (
        <>
            <Link to='/admin/productlist' className='btn btn-light mb-3'>
                Go Back
            </Link>
            <div className="jj">
                <FormContainer >
                    <h1>Edit Product</h1>
                    {loadingUpdate && <Loader />}
                    {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
                    {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> :
                        (
                            <Form onSubmit={submitHandler}>
                                <Form.Group controlId='name'>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type='name'
                                        placeholder='Enter name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='productCode'>
                                    <Form.Label>Product Code</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Enter productCode'
                                        value={productCode}
                                        onChange={(e) => setProductCode(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='price'>
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        type='number'
                                        placeholder='Enter price'
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>
                                {/* <Form.Group controlId='description'>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Enter description'
                                        value={descriptions}
                                        onChange={(e) => setDescriptions(e.target.value)}
                                    ></Form.Control>
                                </Form.Group> */}

                                <Form.Group controlId="featuresDescription">
                                    <Form.Label>Key Features</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3} // Set the number of rows for the text area
                                        placeholder="Enter key features"
                                        value={feature}
                                        onChange={(e) => setFeature(e.target.value)} // Bind description to state
                                    ></Form.Control>

                                </Form.Group>

                                <Form.Group controlId="description" className="mb-3">
                                    <div className="d-flex flex-column align-items-start">
                                        {/* Description Label */}
                                        <Form.Label
                                            className="fw-bold mb-3"
                                            style={{ display: "block", fontSize: "1.2rem" }}
                                        >
                                            Description
                                        </Form.Label>

                                        {/* Formatting Toolbar */}
                                        <div
                                            className="toolbar d-flex flex-wrap align-items-center mb-3"
                                            style={{
                                                border: "1px solid #ccc",
                                                padding: "10px",
                                                borderRadius: "5px",
                                                backgroundColor: "#f9f9f9",
                                                width: "100%",
                                                gap: "10px",
                                            }}
                                        >
                                            {/* Bold Button */}
                                            <button
                                                type="button"
                                                onClick={toggleBold}
                                                className={`btn ${isBold ? "btn-primary" : "btn-light"} btn-sm`}
                                            >
                                                B
                                            </button>

                                            {/* Italic Button */}
                                            <button
                                                type="button"
                                                onClick={toggleItalic}
                                                className={`btn ${isItalic ? "btn-primary" : "btn-light"} btn-sm`}
                                            >
                                                I
                                            </button>

                                            {/* Underline Button */}
                                            <button
                                                type="button"
                                                onClick={toggleUnderline}
                                                className={`btn ${isUnderline ? "btn-primary" : "btn-light"} btn-sm`}
                                            >
                                                U
                                            </button>

                                            {/* Undo */}
                                            <button
                                                type="button"
                                                onClick={undo}
                                                className="btn btn-light btn-sm"
                                            >
                                                Undo
                                            </button>

                                            {/* Redo */}
                                            <button
                                                type="button"
                                                onClick={redo}
                                                className="btn btn-light btn-sm"
                                            >
                                                Redo
                                            </button>

                                            {/* Strikethrough Button */}
                                            <button
                                                type="button"
                                                onClick={toggleStrikethrough}
                                                className={`btn ${isStrikethrough ? "btn-primary" : "btn-light"} btn-sm`}
                                            >
                                                S
                                            </button>

                                            {/* Superscript */}
                                            <button
                                                type="button"
                                                onClick={toggleSuperscript}
                                                className={`btn ${isSuperscript ? "btn-primary" : "btn-light"} btn-sm`}
                                            >
                                                X<sup>2</sup>
                                            </button>

                                            {/* Subscript */}
                                            <button
                                                type="button"
                                                onClick={toggleSubscript}
                                                className={`btn ${isSubscript ? "btn-primary" : "btn-light"} btn-sm`}
                                            >
                                                X<sub>2</sub>
                                            </button>

                                            {/* Text Align Buttons */}
                                            <button
                                                type="button"
                                                onClick={() => setTextAlign("left")}
                                                className={`btn ${textAlign === "left" ? "btn-primary" : "btn-light"} btn-sm`}
                                            >
                                                Left
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => setTextAlign("center")}
                                                className={`btn ${textAlign === "center" ? "btn-primary" : "btn-light"} btn-sm`}
                                            >
                                                Center
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => setTextAlign("right")}
                                                className={`btn ${textAlign === "right" ? "btn-primary" : "btn-light"} btn-sm`}
                                            >
                                                Right
                                            </button>

                                            {/* Text Color */}
                                            <div className="d-flex justify-content-center align-items-center">
                                                <label htmlFor="textColor" className="me-2">
                                                    Color:
                                                </label>
                                                <input
                                                    type="color"
                                                    id="textColor"
                                                    value={textColor}
                                                    onChange={(e) => setTextColor(e.target.value)}
                                                    style={{ width: "30px", height: "30px", border: "none" }}
                                                />
                                            </div>

                                            {/* Text Size */}
                                            <div className="d-flex align-items-center justify-content-center">
                                                <label htmlFor="textSize" className="me-2">
                                                    Size:
                                                </label>
                                                <select
                                                    id="textSize"
                                                    value={textSize}
                                                    onChange={(e) => setTextSize(e.target.value)}
                                                    className="form-select form-select-sm mb-2"
                                                    style={{ width: "80px" }}
                                                >
                                                    <option value="12px">12px</option>
                                                    <option value="14px">14px</option>
                                                    <option value="16px">16px</option>
                                                    <option value="18px">18px</option>
                                                    <option value="20px">20px</option>
                                                    <option value="24px">24px</option>
                                                </select>
                                            </div>

                                            {/* Background Color */}
                                            <div className="d-flex align-items-center text-align-center justify-content-center">
                                                <label htmlFor="bgColor" className="me-2">
                                                    BG Color:
                                                </label>
                                                <input
                                                    className='mb-2'
                                                    type="color"
                                                    id="bgColor"
                                                    value={bgColor}
                                                    onChange={(e) => setBgColor(e.target.value)}
                                                    style={{ width: "30px", height: "30px", border: "none" }}
                                                />
                                            </div>

                                            {/* Add Image */}
                                            {/* <button
                                                type="button"
                                                onClick={insertImage}
                                                className="btn btn-light btn-sm"
                                            >
                                                Add Image
                                            </button> */}

                                            {/* Add Image */}
                                            {/* <div style={{ display: "inline-block", marginLeft: "10px" }}>
                                                <label htmlFor="imageUpload" className="btn btn-light btn-sm mt-2">
                                                    Add Image
                                                </label>
                                                <input
                                                    type="file"
                                                    id="imageUpload"
                                                    accept="image/*"
                                                    style={{ display: "none" }} // Hide the file input
                                                    onChange={handleImageUpload} // Handle image upload
                                                />
                                            </div> */}

                                            <button
                                                type="button"
                                                style={{ marginLeft: "10px", color: "" }}
                                                onClick={addAnchorTag}
                                                className="btn btn-light btn-sm"
                                            >
                                                Add Link
                                            </button>

                                            <button
                                                type="button"
                                                onClick={insertHorizontalRule}
                                                className="btn btn-light btn-sm"
                                            >
                                                HR
                                            </button>

                                            {/* Symbols */}
                                            <button
                                                type="button"
                                                onClick={insertSymbol}
                                                className="btn btn-light btn-sm"
                                            >
                                                Ω
                                            </button>
                                            <button
                                                type="button"
                                                onClick={insertTable}
                                                className="btn btn-light btn-sm"
                                            >
                                                Table
                                            </button>

                                            <div style={{ minWidth: "100px", marginTop: "2px", display: "inline-block" }}>
                                                <Form.Control
                                                    as="select"
                                                    value={fontCategory}
                                                    onChange={(e) => setFontCategory(e.target.value)}
                                                    style={{
                                                        width: "100%",
                                                        height: "30px", // Reduced height to make it smaller
                                                        padding: "5px", // Adjust padding for better fit
                                                        fontSize: "14px", // Optional: Reduce font size for compact appearance
                                                    }}
                                                >
                                                    <option value="Arial">Arial</option>
                                                    <option value="Times New Roman">Times New Roman</option>
                                                    <option value="Courier New">Courier New</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Verdana">Verdana</option>
                                                    <option value="Tahoma">Tahoma</option>
                                                    <option value="Trebuchet MS">Trebuchet MS</option>
                                                    <option value="Impact">Impact</option>
                                                    <option value="Comic Sans MS">Comic Sans MS</option>
                                                    <option value="Lucida Sans">Lucida Sans</option>
                                                    <option value="Palatino Linotype">Palatino Linotype</option>
                                                    <option value="Helvetica">Helvetica</option>
                                                    <option value="Garamond">Garamond</option>
                                                    <option value="Century Gothic">Century Gothic</option>
                                                    <option value="Brush Script MT">Brush Script MT</option>
                                                    <option value="Monaco">Monaco</option>
                                                    <option value="Gill Sans">Gill Sans</option>
                                                    <option value="Candara">Candara</option>
                                                    <option value="Futura">Futura</option>
                                                    <option value="Optima">Optima</option>
                                                    <option value="Rockwell">Rockwell</option>
                                                </Form.Control>
                                            </div>

                                            {/* Blockquote */}
                                            <button
                                                type="button"
                                                onClick={toggleBlockquote}
                                                className={`btn ${isBlockquote ? "btn-primary" : "btn-light"} btn-sm`}
                                            >
                                                Quote
                                            </button>

                                            {/* Bulleted List */}
                                            <button
                                                type="button"
                                                onClick={toggleBulletedList}
                                                className={`btn ${isBulletedList ? "btn-primary" : "btn-light"} btn-sm`}
                                            >
                                                • List
                                            </button>
                                        </div>

                                        {/* Content Editable Div */}
                                        <div
                                            contentEditable
                                            dir="ltr" // Force left-to-right text flow
                                            dangerouslySetInnerHTML={{ __html: description }}
                                            onInput={(e) => setDescription(e.target.innerHTML)}
                                            className="content-editable"
                                            style={{
                                                fontFamily: fontCategory,
                                                fontWeight: isBold ? "bold" : "normal",
                                                fontStyle: isItalic ? "italic" : "normal",
                                                textDecoration: isUnderline ? "underline" : isStrikethrough ? "line-through" : "none",
                                                textAlign: "left", // Align text to the left
                                                color: textColor,
                                                fontSize: isSuperscript || isSubscript ? "smaller" : textSize,
                                                backgroundColor: bgColor,
                                                direction: "ltr", // Ensure left-to-right typing
                                                minHeight: "200px",
                                                width: "100%",
                                                border: "1px solid #ccc",
                                                padding: "15px",
                                                marginTop: "10px",
                                                borderRadius: "5px",
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            }}
                                        />


                                        {/* <div
                                            contentEditable
                                            // onInput directly sets description, to avoid dangerouslySetInnerHTML issues
                                            onInput={(e) => setDescription(e.target.innerText)} // Handle text input properly
                                            className="content-editable"
                                            style={{
                                                fontFamily: fontCategory,
                                                fontWeight: isBold ? "bold" : "normal",
                                                fontStyle: isItalic ? "italic" : "normal",
                                                textDecoration: isUnderline ? "underline" : isStrikethrough ? "line-through" : "none",
                                                textAlign: "left !important", // Ensure text starts from the left
                                                color: textColor,
                                                fontSize: isSuperscript || isSubscript ? "smaller" : textSize,
                                                backgroundColor: bgColor,
                                                direction: "ltr !important", // Force left-to-right typing direction
                                                minHeight: "200px",
                                                border: "1px solid #ccc",
                                                padding: "15px",
                                                marginTop: "10px",
                                                borderRadius: "5px",
                                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                resize: "none", // Prevent resizing if unnecessary
                                                width: "100%",
                                            }}
                                            dangerouslySetInnerHTML={{ __html: description }} // Display the description HTML
                                        /> */}

                                    </div>
                                </Form.Group>


                                <div className="">
                                    {/* Image Section */}
                                    <div className='w-50'>
                                        <Form.Group controlId='image'>
                                            <Form.Label>Image</Form.Label>
                                            <Form.Control
                                                type='text'
                                                placeholder='Enter image url'
                                                value={image}
                                                onChange={(e) => setImage(e.target.value)}
                                            ></Form.Control>
                                            <Form.File id='image-file' label='Choose File' custom onChange={uploadfileHandler}></Form.File>
                                            {uploading && <Loader />}
                                        </Form.Group>
                                    </div>


                                    <div>
                                        <Form.Group controlId="additionalImages" className="mt-3">
                                            <Form.Label>Additional Images</Form.Label>
                                            {additionalImages.map((img, index) => (
                                                <div key={index} className="d-flex  align-items-center">
                                                    {/* Input for additional images */}
                                                    <div className='me-4'>
                                                        <Form.File
                                                            id={`additional-image-${index}`}
                                                            label="Choose File"
                                                            custom
                                                            onChange={(e) => uploadAdditionalImageHandler(e, index)} // Handle each additional image upload
                                                        />
                                                    </div>

                                                    {/* Preview of each additional image */}
                                                    <div className='m-3'>
                                                        {img && (
                                                            <img
                                                                alt={`Additional Preview ${index}`}
                                                                className="img-fluid ms-3"
                                                                src={img}
                                                                style={{ maxWidth: "60px", maxHeight: "60px", minWidth: "60px", minHeight: "40px", objectFit: "contain" }}
                                                            />
                                                        )}

                                                        <Button className='m-3' variant="danger" onClick={() => handleRemoveImageField(index)} style={{}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                                <path d="M11.354 4.646a.5.5 0 0 1 0 .708L8.707 8l2.647 2.646a.5.5 0 1 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 1 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 .708-.708L8 7.293l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                            <br />
                                            <Button
                                                variant="primary"
                                                className=""
                                                onClick={addAdditionalImageField} // Add a new additional image field
                                            >
                                                Add Image
                                            </Button>
                                        </Form.Group>
                                    </div>

                                    {/*  */}

                                    {/* Additional Images */}

                                </div>





                                {/* <Form.Group controlId='brand'>
                                <Form.Label>Brand</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter brand'
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                ></Form.Control>
                            </Form.Group> */}

                                <Form.Group controlId='countInStock'>
                                    <Form.Label>Count In Stock</Form.Label>
                                    <Form.Control
                                        type='number'
                                        placeholder='Enter count in stock'
                                        value={countInStock}
                                        onChange={(e) => setCountInStock(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='category'>
                                    <Form.Label>Category</Form.Label>
                                    <Form.Control
                                        as='select'
                                        value={category}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value=''>Select category</option>
                                        {Array.from(new Set(categories.map(item => item.category))).map((category, index) => (
                                            <option key={index} value={category}>
                                                {category}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId='sub_category'>
                                    <Form.Label>Sub Category</Form.Label>
                                    <Form.Control
                                        as='select'
                                        value={subCategory} // Ensure that value is bound to subCategory state
                                        onChange={handleSubCategoryChange} // Ensure that onChange function is correctly defined
                                    >
                                        <option value=''>Select subCategory</option>
                                        {categories.map((category, index) => ( // Loop through categories array
                                            <option key={index} value={category.sub_category || ''}>
                                                {category.sub_category}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId='brand'>
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Control
                                        as='select'
                                        value={brand}
                                        onChange={handleBrandChange}
                                    >
                                        <option value=''>Select brand</option>
                                        {Array.from(new Set(categories.map(item => item.brand))).map((brand, index) => (
                                            <option key={index} value={brand}>
                                                {brand}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                {/* <Form.Group controlId='name'>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='write description'
                                        value={description}
                                        onChange={(e) => setName(e.target.value)}
                                    ></Form.Control>
                                </Form.Group> */}



                                {/* <Form.Group controlId='description'>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                ></Form.Control>
                            </Form.Group> */}

                                {showRamDetails && (
                                    <Form.Group controlId='specifications'>
                                        <Form.Group controlId='specification'>


                                            <div>
                                                <Form.Label>Specification</Form.Label> <br />
                                                <Button onClick={handleAddButtonClick}>Add </Button>
                                                <br /><br />

                                                {showForm && (
                                                    <div >
                                                        {formData.map((data, titleIndex) => (
                                                            <div key={titleIndex} className='specification_card'>
                                                                <Form.Row>
                                                                    <Form.Group className="mr-3 ">
                                                                        <Form.Label className='mt-6'>Title</Form.Label>
                                                                        <Form.Control
                                                                            type='text'
                                                                            name='title'
                                                                            value={formData[titleIndex].title}
                                                                            onChange={(e) => handleInputChange(titleIndex, -1, 'title', e.target.value)}
                                                                        />
                                                                    </Form.Group>
                                                                    <Button variant="danger" onClick={() => handleRemoveButtonClick(titleIndex)} className='edit_specification'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                                                            <path d="M11.354 4.646a.5.5 0 0 1 0 .708L8.707 8l2.647 2.646a.5.5 0 1 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 1 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 .708-.708L8 7.293l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                                        </svg>
                                                                    </Button>

                                                                </Form.Row>

                                                                <Button onClick={() => handleAddButtonClick1(titleIndex)}>Add </Button> &nbsp;
                                                                {data.dependencies.map((dependency, dependencyIndex) => (
                                                                    <Form.Row key={dependencyIndex}>
                                                                        <Form.Group className="mr-3">
                                                                            <Form.Label className="mt-3">Name</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="ProcessorBrand"
                                                                                value={formData[titleIndex].dependencies[dependencyIndex].ProcessorBrand}
                                                                                onChange={(e) => handleInputChange(titleIndex, dependencyIndex, 'ProcessorBrand', e.target.value)}
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Form.Label className="mt-3">Value</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                name="brand"
                                                                                value={formData[titleIndex].dependencies[dependencyIndex].brand}
                                                                                onChange={(e) => handleInputChange(titleIndex, dependencyIndex, 'brand', e.target.value)}
                                                                            />
                                                                        </Form.Group>
                                                                        &nbsp;&nbsp;
                                                                        <Button variant="danger" onClick={() => handleRemoveButtonClick1(titleIndex, dependencyIndex)} className='edit_specification_value'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                                                                <path d="M11.354 4.646a.5.5 0 0 1 0 .708L8.707 8l2.647 2.646a.5.5 0 1 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 1 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 .708-.708L8 7.293l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                                            </svg>
                                                                        </Button>
                                                                    </Form.Row>
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </Form.Group>
                                    </Form.Group>
                                )}

                                <Button type='submit' variant='primary'>
                                    Update
                                </Button>
                            </Form>
                        )}
                </FormContainer></div>
        </>
    )
}

export default ProductEditScreen
