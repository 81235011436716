import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, Nav, Image, NavDropdown, Container, Row, Col, InputGroup, Form, Button, Modal, Table } from 'react-bootstrap'
import Product from '../components/Product'
import Message from '../components/Message'
import FeaturedCategory from '../components/FeaturedCategory'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import ProductCarousel from '../components/ProductCarousel'
import Meta from '../components/Meta'
import { createProduct, deleteProduct, listProducts } from '../actions/productActions'
import NoticeView from '../components/NoticeView'
import { LinkContainer } from 'react-router-bootstrap'
import { PRODUCT_CREATE_RESET } from '../constants/productConstants'

const HomeScreen = ({ history, match }) => {
  const keyword = match.params.keyword

  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch();
  const [keywords, setKeyword] = useState('')


  const productList = useSelector(state => state.productList)
  const { loading, error, products, page, pages } = productList

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;


  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });

    if (successCreate) {
      history.push(`/admin/product/${createdProduct.slug}/edit`);
    } else {
      dispatch(listProducts("", pageNumber));
    }

    dispatch(listProducts(keyword, pageNumber))
  }, [dispatch, keyword, pageNumber, history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
    pageNumber,])

  const submitHandler = (e) => {
    e.preventDefault()
    if (keywords.trim()) {
      history.push(`/search/${keywords}`)
    }
    else {
      history.push('/')
    }
  }

  // const deleteHandler = (id) => {
  //   if (window.confirm("Are you sure?")) {
  //     dispatch(deleteProduct(id));
  //   }
  // };

  const deleteHandler = (slug) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteProduct(slug));
    }
  };

  const createProductHandler = () => {
    dispatch(createProduct());
  };

  return (
    <>
      <Meta
        title="Interwave Computers - Leading Computer, Laptop & Gadget Shop in Khulna"
        description="Find the latest computers, laptops, and gadgets at Interwave Computers. Best prices in Khulna with fast delivery."
      />
      <Row className=' margin_top'>
        <Col sm={12} xl={12}>
          {!keyword ? <ProductCarousel /> : <Link to='/' className='btn btn-light'>Go Back</Link>}
        </Col>

        {/* <Col>
          <h1>Products</h1>
        </Col>

        <Col>
          <Form onSubmit={submitHandler} inline>

            <Form.Control
              type='text' name='q' onChange={(e) => setKeyword(e.target.value)}
              placeholder='Search Product...'

              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              history={history}
              className="nav_serch_form">
            </Form.Control>

            <Button type='submit' style={{ padding: "0.65rem 1.75rem", backgroundColor: "black" }} className="nav_serch_btn">
              <i class="fas fa-search"></i>
            </Button>
          </Form>
        </Col>

        <Col className="text-right">
          <Button className="my-3 bg-dark" onClick={createProductHandler}>
            <i className="fas fa-plus"></i> Create Product
          </Button>
        </Col> */}

        {/* <Col sm={12} xl={3}>

          <div class="mdl-compare">
            <h4>Compare Products</h4>
            <p>Choose Two Products to Compare</p>
            <form method="post" action="" class="form-cmpr">
              <input type="hidden" name="product_id" />
              <div class="cmpr-field">
                <InputGroup className=" ">
                  <Form.Control
                    placeholder="Search"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"

                    className='nav_serch_form'
                  />
                  <InputGroup.Text id="basic-addon2" className='nav_serch_btn'><i class="fas fa-search"></i></InputGroup.Text>
                </InputGroup>
              </div>
              <div class="cmpr-field">
                <InputGroup className="width_nav_fix ">
                  <Form.Control
                    placeholder="Search"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"

                    className='nav_serch_form'
                  />
                  <InputGroup.Text id="basic-addon2" className='nav_serch_btn'><i class="fas fa-search"></i></InputGroup.Text>
                </InputGroup>
              </div>
              <Link to='/CompareScreen'>
                <button class="btn st-outline mt-3">View Comparison</button></Link>
            </form>

          </div>

          <Image className='img-fluid' src="../images/small_banner.jpg" />
        </Col> */}

      </Row>

      <Row className="align-items-center container-fluid">

      </Row>
      {/* {!keyword ? <ProductCarousel /> : <Link to='/' className='btn btn-light'>Go Back</Link>} */}
      {!keyword ?
        <div>
          <div className="hwrap mb-3 mt-3">
            <NoticeView />
          </div>
        </div> : ""}
      {/* {keyword ?
      <>
      <h4 className='text-center'> Searched Products</h4>
      <p className='text-center'>Check & Get Your Desired Product!</p>
      {loading ?
        <Loader /> : error ?
          <Message variant='danger'>{error}</Message> :
          <>
            <Row>
              {products.map(product => (
                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                  <Product product={product} />
                </Col>
              ))}
            </Row>
            <Paginate pages={pages} page={page} keyword={keyword ? keyword : ''} />
          </>
      }
      </>:""} */}
      {keyword ? (
        <>

          <Col className="d-flex justify-content-center align-items-center">
            <Form onSubmit={submitHandler} className="d-flex">
              <Form.Control
                type="text"
                name="q"
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Search Product..."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                history={history}
                className="nav_serch_form me-2" // Add spacing between input and button
              />
              <Button
                type="submit"
                style={{ padding: "0.65rem 1.75rem", backgroundColor: "black" }}
                className="nav_serch_btn"
              >
                <i className="fas fa-search"></i>
              </Button>
            </Form>
          </Col>

          <h4 className='text-center mt-3'> Searched Products</h4>
          <p className='text-center'>Check & Get Your Desired Product!</p>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : products.length === 0 ? (
            <h5 className='text-center text-bold noresult_found'>No results found. Please try another search.</h5>
          ) : (
            <>
              {/* <Row>
                {products.map(product => (
                  <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                    <Product product={product} />
                  </Col>
                ))}
              </Row> */}
              <Table striped bordered hover responsive className="bg-light table-sm">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>NAME</th>
                    <th>PRICE</th>
                    <th>CATEGORY</th>
                    <th>SUB CATEGORY</th>
                    <th>BRAND</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product._id}>
                      <td>{product._id}</td>
                      <td>{product.name}</td>
                      <td>
                        {" "}
                        {/* <img
                      width={8}
                      className="img-fluid mr-1 mb-1"
                      src="../images/taka_black.svg"
                    /> */}
                        {product.price.toLocaleString()}tk
                      </td>
                      <td>{product.category}</td>
                      <td>{product.subCategory}</td>
                      <td>{product.brand}</td>
                      <td>
                        <LinkContainer to={`/admin/product/${product.slug}/edit`}>
                          <Button variant="light" className="btn-sm">
                            <i className="fas fa-edit"></i>
                          </Button>
                        </LinkContainer>
                        <Button
                          variant="danger"
                          className="btn-sm"
                          onClick={() => deleteHandler(product._id)}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Paginate pages={pages} page={page} keyword={keyword} />
            </>
          )}
        </>
      ) : null}

      <div className="Section_title mt-5">
        <h2 className='text-center'>Featured Category</h2>
        <p className='text-center'>Get Your Best Product from Interwave Computers</p>

        {/* <Row className="justify-content-lg-between g-3 justify-content-xl-between justify-content-md-around justify-content-sm-around justify-content-around pl-3 pr-3"> */}
        <Row className="g-3 text-center">
          {/* <Col lg="2" sm="6" xs="6">

          <Link to="/category/ac">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
             src="../images/AC.svg"
            />
            <span>AC</span>
          </div></Link>
          </Col> */}
          <Col lg="2" sm="6" xs="6">
            <Link to="/category/laptop">
              <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
                <img
                  className="img-fluid"
                  src="../images/Laptop.svg"
                />
                <h3><span>Laptop</span></h3>
              </div>
            </Link>
          </Col>
          <Col lg="2" sm="6" xs="6">

            <Link to="/category/monitor">
              <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
                <img
                  className="img-fluid "
                  src="../images/Monitor.svg"
                />
                <h3><span>Monitor</span></h3>
              </div>
            </Link>
          </Col>
          <Col lg="2" sm="6" xs="6">

            <Link to="/category/motherboard">

              <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
                <img
                  className="img-fluid "
                  src="https://creatuscomputer.com/image/cache/catalog/website/featured-category/motherboard-120x120.png.webp"
                />
                <h3><span>Motherboard</span></h3>
              </div>
            </Link>
          </Col>
          <Col lg="2" sm="6" xs="6">

            <Link to="/category/processor">
              <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
                <img
                  className="img-fluid "
                  src="../images/CPU.svg" />
                <h3><span>Processor</span></h3>
              </div>
            </Link>

          </Col>
          <Col lg="2" sm="6" xs="6">

            <Link to="/category/speaker">
              <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
                <img
                  className="img-fluid "
                  src="../images/Speaker.svg" />
                <h3><span>Speaker</span></h3>
              </div>
            </Link>
          </Col>

          <Col lg="2" sm="6" xs="6">
            <Link to="/category/case">
              <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
                <img
                  className="img-fluid "
                  src="	https://creatuscomputer.com/image/cache/catalog/website/featured-category/case-120x120.png.webp"
                />
                <h3><span>Case</span></h3>
              </div>
            </Link>
          </Col>
          {/* <Col lg="2" sm="6" xs="6">
            <Link to="/category/cpu cooler">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Cpu_Coller.svg" />
            <span>Cpu Cooler</span>
          </div>
          </Link>
              </Col> */}

          <Col lg="2" sm="6" xs="6">
            <Link to="/category/gaming chair">
              <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
                <img
                  className="img-fluid "
                  src="../images/Gaming_Chair.svg" />
                <h3><span>Gaming Chair</span></h3>
              </div>
            </Link>
          </Col>

          <Col lg="2" sm="6" xs="6">
            <Link to="/category/graphics card">
              <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
                <img
                  className="img-fluid "
                  src="../images/Graphics_Card.svg" />
                <h3><span>Graphics Card</span></h3>
              </div>
            </Link>
          </Col>
          <Col lg="2" sm="6" xs="6">
            <Link to="/category/Headset">
              <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
                <img
                  className="img-fluid "
                  src="../images/Headphone.svg" />
                <h3><span>Headset</span></h3>
              </div>
            </Link>
          </Col>
          {/* <Col lg="2" sm="6" xs="6">
<Link to="/category/mousepad">
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Mousepad.svg" />
            <span>Mousepad</span>
          </div>
          </Link>
</Col>      */}
          <Col lg="2" sm="6" xs="6">
            <Link to="/category/power supply">
              <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
                <img
                  className="img-fluid "
                  src="../images/PSU.svg" />
                <h3><span>Power Supply</span></h3>
              </div>
            </Link>
          </Col>
          <Col lg="2" sm="6" xs="6">
            <Link to="/category/mouse">
              <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
                <img
                  className="img-fluid "
                  src="	https://creatuscomputer.com/image/cache/catalog/website/featured-category/mouse-120x120.png.webp"
                />
                <h3><span>Mouse</span></h3>
              </div>
            </Link>
          </Col>

          {/* <Col lg="2" sm="6" xs="6">
<Link to="/category/component"> 
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Component.svg"/>
            <span>Component</span>
          </div>
          </Link>
</Col> */}
          {/* <Col lg="2" sm="6" xs="6">
<Link to="/category/security"> 
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Security.svg"/>
            <span>Security</span>
          </div>
          </Link>
</Col> */}
          <Col lg="2" sm="6" xs="6">
            <Link to="/category/gaming">
              <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
                <img
                  className="img-fluid "
                  src="../images/Gaming.svg" />
                <h3><span>Gaming</span></h3>
              </div>
            </Link>
          </Col>
          {/* <Col lg="2" sm="6" xs="6">
<Link to="/category/network"> 
          <div className="card card_custom pb-2 pl-3 pr-3 text-center mb-3  h-100">
            <img
              className="img-fluid "
              src="../images/Network.svg"/>
            <span>Network</span>
          </div>
          </Link>
</Col>       */}

        </Row>
      </div>
      <FeaturedCategory />
      {!keyword ?
        <>
          <h2 className='text-center'> Featured Products</h2>
          <p className='text-center'>Check & Get Your Desired online Product!</p>
          {loading ?
            <Loader /> : error ?
              <Message variant='danger'>{error}</Message> :
              <>
                <Row>
                  {products.map(product => (
                    <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                      <Product product={product} />
                    </Col>
                  ))}
                </Row>
                <Paginate pages={pages} page={page} keyword={keyword ? keyword : ''} />
              </>
          }
        </> : ""}

      {/* <h4 className='text-center'> Best Deals</h4>
      <p className='text-center'>Check & Get Your Desired Product!</p>

      {loading ?
        <Loader /> : error ?
          <Message variant='danger'>{error}</Message> :
          <>
            <Row>
              {products.map(product => (
                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                  <Product product={product} />
                </Col>
              ))}
            </Row>
            <Paginate pages={pages} page={page} keyword={keyword ? keyword : ''} />
          </>
      } */}

      <section className="text-start mt-5 site_description">
        <Row>
          <Col>
            <div className="mb-2" style={{ textAlign: "justify" }}>
              <h1>
                <strong>
                  The Leading Retail and Online Shop for Computers, Laptops, Monitors & Accessories in Khulna
                </strong>
              </h1>
              <p>
                Welcome to Interwave Computers, Khulna’s trusted destination for all your tech needs. Whether you’re searching for high-performance laptops, custom-built gaming PCs, <a href="https://interwavecomputers.com/category/monitor" target="_blank" style={{ color: '#f18e18', textDecoration: 'underline', fontWeight: 'bold' }}> monitors</a> , or computer accessories, we offer a wide range of top-quality products and services to cater to tech enthusiasts, professionals, and gamers alike.
              </p>
            </div>
            <div className="mb-2">
              <h2>
                <strong>Best Laptop Shop in Khulna</strong>
              </h2>
              <p>
                Explore a wide selection of laptops from top global brands like Dell, HP, Lenovo, Asus, and Acer at Interwave Computers. Whether you're working from home, studying, or gaming, we have the <a href="https://interwavecomputers.com/category/laptop" target="_blank" style={{ color: '#f18e18', textDecoration: 'underline', fontWeight: 'bold' }}> best laptop in khulna</a> for you, and our experts are here to help you make the best choice for your needs and budget.
              </p>
            </div>
            <div className="mb-2">
              <h2>
                <strong>
                  Top Desktop PC Shop in Khulna
                </strong>
              </h2>
              <p>
                Looking for the perfect desktop PC? Interwave Computers offers custom-built PCs, brand-name desktops, and gaming setups tailored to your needs. Whether you need a professional editing PC or a gaming rig, our team is here to build your dream PC using high-quality components.
              </p>
            </div>
            <div className="mb-2">
              <h2>
                <strong>
                  Your Go-To Gaming PC Shop in Khulna
                </strong>
              </h2>
              <p>
                For gaming enthusiasts, we offer a broad range of gaming PCs, <a href="https://interwavecomputers.com/category/gaming" target="_blank" style={{ color: '#f18e18', textDecoration: 'underline', fontWeight: 'bold' }}>gaming laptops,</a> gaming monitors, and accessories. Build the ultimate gaming setup with products from top brands, including <a href="https://interwavecomputers.com/category/graphics%20card" target="_blank" style={{ color: '#f18e18', textDecoration: 'underline', fontWeight: 'bold' }}>high-performance graphics cards,</a> RGB lights, and gaming chairs.
              </p>
            </div>
            <div className="mb-2">
              <h2>
                <strong>
                  Comprehensive IT Services & Computer Repair
                </strong>
              </h2>
              <p>
                As a leading <a href="https://interwavecomputers.com/" target="_blank" style={{ color: '#f18e18', textDecoration: 'underline', fontWeight: 'bold' }}>IT service provider in Khulna,</a> we offer expert computer repair and laptop repair services. Whether it’s a hardware upgrade, virus removal, or system optimization, our skilled technicians are here to help.
              </p>
            </div>

            <div className="mb-2">
              <h2>
                <strong>
                  Security, Printer & Air Conditioner in Khulna
                </strong>
              </h2>
              <p>
                In addition to computers, we bring you the latest Security products, Printer & Air Conditioner near you in khulna. From <a href="https://interwavecomputers.com/category/cc%20camera" target="_blank" style={{ color: '#f18e18', textDecoration: 'underline', fontWeight: 'bold' }}>CC Camera</a> and <a href="https://interwavecomputers.com/category/printer" target="_blank" style={{ color: '#f18e18', textDecoration: 'underline', fontWeight: 'bold' }}>Printer</a> to <a href="https://interwavecomputers.com/category/headset" target="_blank" style={{ color: '#f18e18', textDecoration: 'underline', fontWeight: 'bold' }}>Headphone</a> and computer accessories, you’ll find everything you need to stay connected and powered up.
              </p>
            </div>

            <div className="mb-2">
              <h2>
                <strong>
                  Why Choose Interwave Computers?
                </strong>
              </h2>
              <p>
                <strong>Expert Support:</strong> Our knowledgeable team is always ready to assist you in selecting the perfect tech products for your needs. <br></br>
                <strong>Competitive Pricing:</strong> We offer the best prices on laptops, desktops, monitors, accessories, and more. <br></br>
                <strong>Fast Nationwide Delivery:</strong> Enjoy quick and reliable delivery across Khulna and beyond. <br></br>
                <strong>Trusted Reputation:</strong> With years of experience, we are Khulna’s go-to tech shop for computers and IT services. <br></br>
                Visit us today at Interwave Computers in Khulna or shop online for the best deals on computers, laptops, monitors, and accessories!
              </p>
            </div>
          </Col>
        </Row>

      </section>
      <section className="mb-2">

        {/* <Row>
          <Col>
            <Row className="g-3">
              {" "}
              <Col xl={2} md={4} lg={3} sm={6}>

                <div className="card card_helpline mt-4 p-3">
                <div class="d-flex ">
  <div class="flex-shrink-0">
  <i class="fas fa-phone-alt mr-3 mt-2 icon_fott"></i>
  </div>
  <div class="flex-grow-1 ms-3">
  <h6 className="mb-2 text-uppercase">DESKTOP</h6>
   <div className="mb-2">01710000000</div>
  </div>
</div></div>
              </Col>
              <Col xl={2} md={4} lg={3} sm={6}>
              <div className="card card_helpline mt-4 p-3">
                <div class="d-flex ">
  <div class="flex-shrink-0">
  <i class="fas fa-phone-alt mr-3 mt-2 icon_fott"></i>
  </div>
  <div class="flex-grow-1 ms-3">
  <h6 className="mb-2 text-uppercase">laptop</h6>
   <div className="mb-2">01710000000</div>
  </div>
</div></div>
              </Col>
              <Col xl={2} md={4} lg={3} sm={6}>
              <div className="card card_helpline mt-4 p-3">
                <div class="d-flex ">
  <div class="flex-shrink-0">
  <i class="fas fa-phone-alt mr-3 mt-2 icon_fott"></i>
  </div>
  <div class="flex-grow-1 ms-3">
  <h6 className="mb-2 text-uppercase">service</h6>
   <div className="mb-2">01710000000</div>
  </div>
</div></div>
              </Col>{" "}
              <Col xl={2} md={4} lg={3} sm={6}>
              <div className="card card_helpline mt-4 p-3">
                <div class="d-flex ">
  <div class="flex-shrink-0">
  <i class="fas fa-phone-alt mr-3 mt-2 icon_fott"></i>
  </div>
  <div class="flex-grow-1 ms-3">
  <h6 className="mb-2 text-uppercase">Accessories</h6>
   <div className="mb-2">01710000000</div>
  </div>
</div></div>
              </Col>
              <Col xl={2} md={4} lg={3} sm={6}>
              <div className="card card_helpline mt-4 p-3">
                <div class="d-flex ">
  <div class="flex-shrink-0">
  <i class="fas fa-phone-alt mr-3 mt-2 icon_fott"></i>
  </div>
  <div class="flex-grow-1 ms-3">
  <h6 className="mb-2 text-uppercase">helpline</h6>
   <div className="mb-2">01710000000</div>
  </div>
</div></div>
              </Col>
              <Col xl={2} md={4} lg={3} sm={6}>
              <div className="card card_helpline mt-4 p-3">
                <div class="d-flex ">
  <div class="flex-shrink-0">
  <i class="fas fa-phone-alt mr-3 mt-2 icon_fott"></i>
  </div>
  <div class="flex-grow-1 ms-3">
  <h6 className="mb-2 text-uppercase">Rma</h6>
   <div className="mb-2">01710000000</div>
  </div>
</div></div>
              </Col>
            </Row>
          </Col>
        </Row> */}

      </section>
    </>
  )
}

export default HomeScreen
