
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import SigninScreen from "./screens/SigninScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import CompareScreen from "./screens/CompareScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import OrderListScreen from "./screens/OrderListScreen";
import CategoryPagesScreen from "./screens/CategoryPagesScreen";
import EmiTermScreen from "./screens/EmiTermScreen";
import TermsandCondScreen from "./screens/TermsandCondScreen";
import AboutUsScreen from "./screens/AboutUsScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import OnlineDeliveryScreen from "./screens/OnlineDeliveryScreen";
import RefundReturnPolicyScreen from "./screens/RefundReturnPolicyScreen";
import PointPolicyScreen from "./screens/PointPolicyScreen";
import ContactUsScreen from "./screens/ContactUsScreen";
import BrandScreen from "./screens/BrandScreen";
import BlogScreen from "./screens/BlogScreen";
import BlogDetailsScreen from "./screens/BlogDetailsScreen";
import ComplainAdviceScreen from "./screens/ComplainAdviceScreen";
import OnlineServiceSupportScreen from "./screens/OnlineServiceSupportScreen";
import OffersListScreen from "./screens/Offers/OffersListScreen";
import OfferCreateScreen from "./screens/Offers/OfferCreateScreen";
import OfferEditScreen from "./screens/Offers/OfferEditScreen";
import NoticeListScreen from "./screens/Notice/NoticeListScreen";
import NoticeCreateScreen from "./screens/Notice/NoticeCreateScreen";
import NoticeEditScreen from "./screens/Notice/NoticeEditScreen";
import PcBuilderScreen from "./screens/PcBuilderScreen";
import PcBuilderChooseScreen from "./screens/PcBuilderChooseScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import Product from './components/Product';
import { Helmet } from 'react-helmet';


function ScrollToTop() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}



const App = () => {
  return (
    <Router>
      <Helmet>
        <meta name="google-site-verification" content="rhDtn8yNyCUZ1QGjZHdMC44Yto3teREnwYQ9xnjP0pU" />
      </Helmet>

      <Header />
      <ScrollToTop />
      <main className="py-3">
        <Container>
          <Route path="/PcBuilderScreen" component={PcBuilderScreen} />
          <Route path="/PcBuilderChooseScreen" component={PcBuilderChooseScreen} />
          <Route path="/order/:id" component={OrderScreen} />
          <Route path="/CompareScreen" component={CompareScreen} />
          <Route path="/shipping" component={ShippingScreen} />
          <Route
            path="/ComplainAdviceScreen"
            component={ComplainAdviceScreen}
          />
          <Route
            path="/OnlineServiceSupportScreen"
            component={OnlineServiceSupportScreen}
          />
          <Route
            path="/RefundReturnPolicyScreen"
            component={RefundReturnPolicyScreen}
          />
          <Route path="/PointPolicyScreen" component={PointPolicyScreen} />
          <Route path="/ContactUsScreen" component={ContactUsScreen} />
          <Route path="/BrandScreen" component={BrandScreen} />
          <Route path="/BlogScreen" component={BlogScreen} />
          <Route path="/BlogDetailsScreen" component={BlogDetailsScreen} />
          <Route path="/emi-terms" component={EmiTermScreen} />
          <Route path="/TermsandCondScreen" component={TermsandCondScreen} />
          <Route path="/AboutUsScreen" component={AboutUsScreen} />
          <Route path="/PrivacyPolicyScreen" component={PrivacyPolicyScreen} />
          <Route
            path="/OnlineDeliveryScreen"
            component={OnlineDeliveryScreen}
          />
          <Route path="/category/:keyword" component={CategoryPagesScreen} />
          {/* <Route path="/category/:keyword/:pageNumber" component={CategoryPagesScreen} exact /> */}
          <Route path="/payment" component={PaymentScreen} />
          <Route path="/placeorder" component={PlaceOrderScreen} />
          <Route path="/login" component={SigninScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route path="/profile" component={ProfileScreen} />
          {/* <Route path="/product/:id" component={ProductScreen} /> */}
          <Route path="/product/:slug" component={ProductScreen} />
          <Route path="/cart/:id?" component={CartScreen} />
          <Route path="/admin/userlist" component={UserListScreen} />
          <Route path="/admin/user/:id/edit" component={UserEditScreen} />
          <Route
            path="/admin/productlist"
            component={ProductListScreen}
            exact
          />
          <Route
            path="/admin/productlist/:pageNumber"
            component={ProductListScreen}
            exact
          />

          <Route
            path="/cat/:keyword/:pageNumber"
            component={CategoryPagesScreen}
            exact
          />
          <Route path="/ResetPasswordScreen" component={ResetPasswordScreen} />
          {/* <Route path="/admin/product/:id/edit" component={ProductEditScreen} /> */}
          <Route path="/admin/product/:slug/edit" component={ProductEditScreen} />
          <Route path="/admin/orderlist" component={OrderListScreen} />

          <Route path="/admin/offerslist" component={OffersListScreen} />
          <Route path="/admin/offer/create/" component={OfferCreateScreen} />
          <Route path="/admin/offer/:id/edit" component={OfferEditScreen} />

          <Route path="/admin/notice/list" component={NoticeListScreen} />
          <Route path="/admin/notice/create/" component={NoticeCreateScreen} />
          <Route path="/admin/notice/edit/:id" component={NoticeEditScreen} />

          <Route path="/search/:keyword" component={HomeScreen} exact />
          <Route path="/page/:pageNumber" component={HomeScreen} exact />


          {/* <Route path="/products/:brand/:slug" component={Product} /> */}

          {/* <Route path="/product/:brand" component={Product} exact /> */}


          <Route
            path="/search/:keyword/page/:pageNumber"
            component={HomeScreen}
            exact
          />

          <Route path="/" component={HomeScreen} exact />
        </Container>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
