import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import Rating from "../components/Rating";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Meta from "../components/Meta";
import Product from "../components/Product";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import { listProducts } from "../actions/productActions";
import {
  listProductDetails,
  createProductReview,
} from "../actions/productActions";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";
import { render } from "react-dom";
// import Carousel from "../components/Slide";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FsLightbox from "fslightbox-react";
import { Carousel } from "react-responsive-carousel";
import {

  GlassMagnifier
} from "react-image-magnifiers";


import DOMPurify from "dompurify";


const ProductScreen = ({ history, match }) => {
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const { success: successProductReview, error: errorProductReview } =
    productReviewCreate;

  const sanitizedDescription = DOMPurify.sanitize(product.description);


  useEffect(() => {
    if (successProductReview) {
      alert("Review Submitted!");
      setRating(0);
      setComment("");
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
    console.log('hughfg', match.params.id)
    // dispatch(listProductDetails(match.params.id));
    dispatch(listProductDetails(match.params.slug));
  }, [dispatch, match, successProductReview]);

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(match.params.id, {
        rating,
        comment,
      })
    );
  };
  const keyword = match.params.keyword;

  const pageNumber = match.params.pageNumber || 1;

  const productList = useSelector((state) => state.productList);
  const { products, page, pages } = productList;

  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber));
  }, [dispatch, keyword, pageNumber]);

  const [toggler, setToggler] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [images, setImages] = useState([]);


  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const magnifierProps = {
    allowOverflow: true,
    square: true,
    magnifierSize: '60%',
    zoomLevel: 4,
    borderSize: 1,
    borderColor: 'rgba(0,0,0,0.5)' // Adjust as needed
  };

  const handleThumbClick = (index) => {
    setSelectedImageIndex(index);
  };
  return (
    <>
      <Link className="btn btn-light mb-3" to="/category/ac">
        Go Back
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Meta title={product.name} />
          <Row className="ml-0 mr-0" >
            <Col md={6} className="bg_white pl-0 " >

              <FsLightbox
                toggler={toggler}
                sources={[
                  product.image,
                  ...(product.additionalImages ? product.additionalImages : [])
                ].map((image, index) => (
                  <img key={index} alt="" className="img-fluid" src={image} />
                ))}
              />
              <div>
                <Carousel selectedItem={selectedImageIndex}>
                  {[
                    product.image,
                    ...(product.additionalImages ? product.additionalImages : [])
                  ].map((image, index) => (
                    <div key={index}>
                      <GlassMagnifier
                        imageSrc={image}
                        largeImageSrc={image} // Use the same image for the large view
                        {...magnifierProps}
                      />
                    </div>
                  ))}
                </Carousel>
                <div className="thumbs-wrapper axis-vertical d-flex mb-2">
                  {[
                    product.image,
                    ...(product.additionalImages ? product.additionalImages : [])
                  ].map((image, index) => (
                    // Check if the image exists
                    image ? (
                      <div key={index} onClick={() => handleThumbClick(index)} className="thumbs-wrapper-box">
                        <img
                          src={image}
                          alt={`Thumbnail ${index}`}
                          style={{
                            width: '100%',
                            height: '100px',
                            marginRight: '10px',
                            cursor: 'pointer'
                          }}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '../images/No-Image.png';
                            e.target.alt = 'No Image Available';
                          }}
                        />
                      </div>
                    ) : null // Render nothing if the image doesn't exist
                  ))}
                </div>
              </div>
            </Col>


            <Col md={6} className="bg_white pr-0">

              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h2 className="product_namee mb-0">{product.name}</h2>
                </ListGroup.Item>
                <ListGroup.Item className="product_code">
                  Product Code: {product.productCode}
                </ListGroup.Item>
                <ListGroup.Item className="product_price">
                  Price:{" "}
                  {product.price ? (
                    <>
                      <img
                        width={12}
                        className="img-fluid mr-1 mb-1"
                        src="../images/taka_green.svg"
                      />
                      {product.category === "AC" ? (
                        <>
                          <s style={{ marginRight: '5px' }}>
                            {product.price.toLocaleString()}
                          </s>
                          <strong style={{ color: 'green' }}>
                            {(product.price - 3000).toLocaleString()}
                          </strong></>
                      ) : (
                        <strong style={{ color: 'green' }}>
                          {product.price.toLocaleString()}
                        </strong>
                      )}
                    </>
                  ) : (
                    "N/A"
                  )}
                </ListGroup.Item>
                <ListGroup.Item>
                  <Rating
                    value={product.rating}
                    text={`${product.numReviews} reviews`}
                  />
                </ListGroup.Item>

                <ListGroup.Item>
                  <h3 className="product_name pb-0 pt-0 mb-0">Key Features</h3>
                  {product.feature}
                </ListGroup.Item>
                <ListGroup.Item className="product_price mb-3">
                  {product.countInStock > 0 ? "In Stock" : "Out Of Stock"}
                </ListGroup.Item>
              </ListGroup>
              <div className="d-flex">
                {product.countInStock > 0 && (
                  <ListGroup.Item className="mr-3 ml-4 customWidth">
                    <Form.Control
                      className="form_c"
                      as="select"
                      value={qty}
                      onChange={(e) => setQty(e.target.value)}
                    >
                      {[...Array(product.countInStock).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </Form.Control>
                  </ListGroup.Item>
                )}

                <Button
                  onClick={addToCartHandler}
                  className="btn-block color_dark customWidth"
                  type="button"
                  disabled={product.countInStock === 0}
                >
                  Add To Cart
                </Button>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-4 display_small ">
                <div class="col-inner ">
                  <div class="icon-box featured-box d-flex align-items-center align-items-center">
                    <div class="icon-inner">
                      <img
                        width="50"
                        height="50"
                        src="https://static.vecteezy.com/system/resources/thumbnails/003/344/958/small/hotline-symbol-with-headphones-and-customer-icon-vector.jpg"
                        class="attachment-medium size-medium"
                        alt=""
                        loading="lazy"
                      />
                    </div>

                    <div class="icon-box-text last-reset ml-2">
                      <p className="mb-0">
                        <strong>Hotline Support:</strong>{" "}
                        <span>(10am - 8pm)</span>
                      </p>
                      <p className="mb-0">
                        <span>
                          <strong>
                            <a href="#">Call Now:</a> {" "}
                          </strong>
                          <a href="tel:(880) 1777-171413">(880) 1777-171413</a>
                          <strong>
                            {" "}
                            {/* | */}
                            {/* <a href="mailto:" target="_blank" rel="noopener">
                              Email
                            </a> */}
                          </strong>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-inner  ml-2">
                  <div class="icon-box featured-box circle icon-box-left text-left is-small d-flex align-items-center ">
                    <div class="icon-box-img">
                      <div class="icon-inner">
                        <img
                          width="50"
                          height="50"
                          src="https://cdn1.vectorstock.com/i/1000x1000/66/40/motorcycle-delivery-food-isolated-icon-vector-10256640.jpg"
                          class="attachment-medium size-medium"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div class="icon-box-text last-reset ml-2">
                      <p className="mb-0">
                        <strong>
                          Delivery Time:

                        </strong>
                        <span>(48 Hours / 3 WD)</span>
                      </p>
                      <p className="mb-0">
                        <span>Depends on Location</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12}>
              <Tabs
                defaultActiveKey="description"
                id="uncontrolled-tab-example"
                className="mb-3 nav_tabs mt-5"
              >
                <Tab eventKey="description" title="Specification">
                  <Table
                    responsive
                    striped

                    hover
                    size="sm"
                    className="product_table bg-light"
                  >
                    {product.specifications && product.specifications.map((specification, index) => (
                      <>
                        <thead>
                          <tr>
                            <th class="heading_row" colspan="3">
                              {specification.name}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {specification.values.map((value, index) => (
                            <tr>
                              <td className="t_name">{value.name}</td>
                              <td>{value.value}</td>
                            </tr>

                          ))}
                        </tbody>
                      </>
                    ))}
                  </Table>
                </Tab>


                {/* <Tab eventKey="" title="Description">

                  <Table
                    responsive
                    striped

                    hover
                    size="sm"
                    className="product_table bg-light"
                  >
                    {product.specifications && product.specifications.map((specification, index) => (
                      <>
                        <thead>
                          <tr>
                            <th class="heading_row" colspan="3">
                              {specification.name}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {specification.values.map((value, index) => (
                            <tr>
                              <td className="t_name">{value.name}</td>
                              <td>{value.value}</td>
                            </tr>

                          ))}
                        </tbody>
                      </>
                    ))}
                  </Table>
                </Tab> */}

                {/* <Tab eventKey="" title="Description">
                  <h5></h5>

                  <ListGroup variant="flush">
                    {product.reviews.map((review) => (
                      <ListGroup.Item key={review._id}>
                        <strong>{review.name}</strong>
                        <Rating value={review.rating} />
                        <p>{review.createdAt.substring(0, 10)}</p>.
                        <p>{review.comment}</p>
                      </ListGroup.Item>
                    ))}
                    <ListGroup.Item>

                      {errorProductReview && (
                        <Message variant="danger">{errorProductReview}</Message>
                      )}
                      {userInfo ? (
                        <Form onSubmit={submitHandler}>

                          <Form.Group controlId="comment" style={{
                            fontFamily: product.fontCategory || "Arial",
                            fontWeight: product.isBold ? "bold" : "normal",
                            fontStyle: product.isItalic ? "italic" : "normal",
                            textDecoration: product.isUnderline ? "underline" : "none",
                          }}>

                            <p >{product.description}</p>                       
                        </Form.Group>

                        </Form>
                      ) : (
                        <Message>
                          Please <Link to="/login">sign in</Link> to write a
                          review{" "}
                        </Message>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Tab> */}


                <Tab eventKey="reviews" title="Reviews">
                  <h2></h2>
                  {product.reviews.length === 0 && (
                    <Message>No Reviews</Message>
                  )}
                  <ListGroup variant="flush">
                    {product.reviews.map((review) => (
                      <ListGroup.Item key={review._id}>
                        <strong>{review.name}</strong>
                        <Rating value={review.rating} />
                        <p>{review.createdAt.substring(0, 10)}</p>
                        <p>{review.comment}</p>
                      </ListGroup.Item>
                    ))}
                    <ListGroup.Item>
                      <h2>Write a Customer Review</h2>
                      {errorProductReview && (
                        <Message variant="danger">{errorProductReview}</Message>
                      )}
                      {userInfo ? (
                        <Form onSubmit={submitHandler}>
                          <Form.Group controlId="rating">
                            <Form.Label>Rating</Form.Label>
                            <Form.Control
                              as="select"
                              value={rating}
                              onChange={(e) => setRating(e.target.value)}
                            >
                              <option value="">Select...</option>
                              <option value="1">1 - Poor</option>
                              <option value="2">2 - Fair</option>
                              <option value="3">3 - Good</option>
                              <option value="4">4 - Very Good</option>
                              <option value="5">5 - Excellent</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="comment">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                              as="textarea"
                              row="3"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <Button type="submit" variant="primary">
                            Submit
                          </Button>
                        </Form>
                      ) : (
                        <Message>
                          Please <Link to="/login">sign in</Link> to write a
                          review{" "}
                        </Message>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Tab>


              </Tabs>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProductScreen;
