import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, keywords, description, canonical }) => {
    const isHomePage = title === 'Interwave Computers - Leading Computer, Laptop & Gadget Shop in Khulna';

    return (
        <Helmet>
            <title>{title ? `${title} | Interwave Computers` : 'Interwave Computers - Leading Computer, Laptop & Gadget Shop in Khulna'}</title>
            <meta
                name="description"
                content={isHomePage
                    ? 'Interwave Computers is the Best Laptop, Computer, Gaming PC, Component, Accessories, and Gadget retail & Online shop in Khulna. Order online to get delivery in Khulna.'
                    : `Buy ${title} from Khulna shop. Order online & get faster delivery in Khulna.`}
            />
            <meta name="keywords" content={keywords || 'laptops, computers, gaming PC, components, accessories, gadgets, buy online Khulna'} />
            {canonical && <link rel="canonical" href={canonical} />}
        </Helmet>
    );
};

Meta.defaultProps = {
    title: 'Interwave Computers - Leading Computer, Laptop & Gadget Shop in Khulna',
    description: 'Interwave Computers is the Best Laptop, Computer, Gaming PC, Component, Accessories, and Gadget retail & Online shop in Khulna. Order online to get delivery in Khulna.',
    keywords: 'laptops, computers, gaming PC, components, accessories, gadgets, buy online Khulna'
};

export default Meta;

